@use '@/scss/underscore' as _;

.appNotification {
  position: absolute;
  max-width: 520px;
}

:global(body.mobile) {
  .appNotification {
    top: _.unit(6);
    left: _.unit(5);
    right: _.unit(5);
    margin: 0 auto;
  }
}

:global(body.desktop) {
  .appNotification {
    left: 50%;
    transform: translate(-50%);
    width: max-content;
  }
}
