const translation = {
    input: {
        username: 'Nome de usuário',
        password: 'Senha',
        email: 'E-mail',
        phone_number: 'Número de telefone',
        confirm_password: 'Confirme a senha',
        search_region_code: 'Pesquisar código de região',
    },
    secondary: {
        social_bind_with: 'Já tinha uma conta? Faça login no link {{methods, list(type: disjunction;)}} com sua identidade social.',
    },
    action: {
        sign_in: 'Entrar',
        continue: 'Continuar',
        create_account: 'Criar conta',
        create_account_without_linking: 'Criar conta sem vincular',
        create: 'Criar',
        enter_passcode: 'Inserir código de verificação',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        save_password: 'Salvar senha',
        bind: 'Link com {{address}}',
        bind_and_continue: 'Linkar e continuar',
        back: 'Voltar',
        nav_back: 'Voltar',
        agree: 'Aceito',
        got_it: 'Entendido',
        sign_in_with: 'Continuar com {{name}}',
        forgot_password: 'Esqueceu sua senha?',
        switch_to: 'Trocar para {{method}}',
        sign_in_via_passcode: 'Fazer login com código de verificação',
        sign_in_via_password: 'Fazer login com senha',
        change: 'Alterar {{change}}',
        link_another_email: 'Vincular outro e-mail',
        link_another_phone: 'Vincular outro telefone',
        link_another_email_or_phone: 'Vincular outro e-mail ou telefone',
        show_password: 'Mostrar senha',
    },
    description: {
        email: 'e-mail',
        phone_number: 'número de telefone',
        username: 'nome de usuário',
        reminder: 'Lembrete',
        not_found: '404 Não Encontrado',
        agree_with_terms: 'Eu li e concordo com os ',
        agree_with_terms_modal: 'Para continuar, por favor, concorde com os <link></link>.',
        terms_of_use: 'Termos de uso',
        sign_in: 'Entrar',
        privacy_policy: 'Política de privacidade',
        create_account: 'Criar conta',
        or: 'ou',
        and: 'e',
        enter_passcode: 'O código de verificação foi enviado para o seu {{address}} {{target}}',
        passcode_sent: 'O código de verificação foi reenviado',
        resend_after_seconds: 'Reenviar depois <span>{{seconds}}</span> segundos',
        resend_passcode: 'Reenviar código de verificação',
        create_account_id_exists: 'A conta com {{type}} {{value}} já existe, gostaria de entrar?',
        link_account_id_exists: 'A conta com {{type}} {{value}} já existe, gostaria de vincular?',
        sign_in_id_does_not_exist: 'A conta com {{type}} {{value}} não existe, gostaria de criar uma nova conta?',
        sign_in_id_does_not_exist_alert: 'A conta com {{type}} {{value}} não existe.',
        create_account_id_exists_alert: 'A conta com {{type}} {{value}} está vinculada a outra conta. Por favor, tente outro {{type}}.',
        social_identity_exist: 'O {{type}} {{value}} está vinculado a outra conta. Por favor, tente outro {{type}}.',
        bind_account_title: 'Vincular ou criar conta',
        social_create_account: 'Você pode criar uma nova conta.',
        social_link_email: 'É possível vincular outro e-mail',
        social_link_phone: 'É possível vincular outro telefone',
        social_link_email_or_phone: 'É possível vincular outro e-mail ou telefone',
        social_bind_with_existing: 'Encontramos uma conta relacionada registrada e você pode vinculá-la diretamente.',
        reset_password: 'Esqueceu a senha',
        reset_password_description: 'Digite o {{types, list(type: disjunction;)}} à sua conta e enviaremos a você o código de verificação para redefinir sua senha.',
        new_password: 'Nova senha',
        set_password: 'Configurar senha',
        password_changed: 'Senha alterada',
        no_account: 'Ainda não tem conta? ',
        have_account: 'Já tinha uma conta?',
        enter_password: 'Digite a senha',
        enter_password_for: 'Entre com a senha para {{method}} {{value}}',
        enter_username: 'Definir nome de usuário',
        enter_username_description: 'O nome de usuário é uma alternativa para fazer login. O nome de usuário deve conter apenas letras, números e sublinhados.',
        link_email: 'Linkar e-mail',
        link_phone: 'Linkar telefone',
        link_email_or_phone: 'Linkar e-mail ou telefone',
        link_email_description: 'Para maior segurança, vincule seu e-mail à conta.',
        link_phone_description: 'Para maior segurança, vincule seu telefone à conta.',
        link_email_or_phone_description: 'Para maior segurança, vincule seu e-mail ou telefone à conta.',
        continue_with_more_information: 'Para maior segurança, preencha os detalhes da conta abaixo.',
        create_your_account: 'Crie sua conta',
        sign_in_to_your_account: 'Faça login na sua conta',
        no_region_code_found: 'Não foi possível encontrar o código de região do seu telefone.',
    },
    error: {
        general_required: `{{types, list(type: disjunction;)}} é obrigatório`,
        general_invalid: `O {{types, list(type: disjunction;)}} é inválido`,
        username_required: 'Nome de usuário é obrigatório',
        password_required: 'Senha é obrigatório',
        username_exists: 'O nome de usuário já existe',
        username_should_not_start_with_number: 'O nome de usuário não deve começar com um número',
        username_invalid_charset: 'O nome de usuário deve conter apenas letras, números ou sublinhados.',
        invalid_email: 'O e-mail é inválido',
        invalid_phone: 'O número de telefone é inválido',
        password_min_length: 'A senha requer um mínimo de {{min}} caracteres',
        passwords_do_not_match: 'Suas senhas não correspondem. Por favor, tente novamente.',
        invalid_password: 'A senha requer um mínimo de {{min}} caracteres e contém uma mistura de letras, números e símbolos.',
        invalid_passcode: 'O código de verificação é inválido',
        invalid_connector_auth: 'A autorização é inválida',
        invalid_connector_request: 'Os dados do conector são inválidos',
        unknown: 'Erro desconhecido. Por favor, tente novamente mais tarde.',
        invalid_session: 'Sessão não encontrada. Volte e faça login novamente.',
        timeout: 'Tempo limite excedido. Por favor, tente novamente mais tarde.',
    },
    demo_app: {
        notification: 'Dica: Crie uma conta primeiro para testar a experiência de login.',
    },
};
const ptBR = Object.freeze({
    translation,
});
export default ptBR;
