// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { arbitraryObjectGuard } from './../foundations/index.js';
const createGuard = z.object({
    key: z.string().max(256),
    value: arbitraryObjectGuard.optional(),
});
const guard = z.object({
    key: z.string().max(256),
    value: arbitraryObjectGuard,
});
export const Systems = Object.freeze({
    table: 'systems',
    tableSingular: 'system',
    fields: {
        key: 'key',
        value: 'value',
    },
    fieldKeys: [
        'key',
        'value',
    ],
    createGuard,
    guard,
});
