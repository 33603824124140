// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { hookEventGuard, hookConfigGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().max(21),
    event: hookEventGuard,
    config: hookConfigGuard,
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    event: hookEventGuard,
    config: hookConfigGuard,
    createdAt: z.number(),
});
export const Hooks = Object.freeze({
    table: 'hooks',
    tableSingular: 'hook',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        event: 'event',
        config: 'config',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'event',
        'config',
        'createdAt',
    ],
    createGuard,
    guard,
});
