import { languages, fallback } from '@logto/language-kit';
import { z } from 'zod';
import de from './locales/de.js';
import en from './locales/en.js';
import fr from './locales/fr.js';
import ko from './locales/ko.js';
import ptBR from './locales/pt-br.js';
import ptPT from './locales/pt-pt.js';
import ru from './locales/ru.js';
import trTR from './locales/tr-tr.js';
import zhCN from './locales/zh-cn.js';
export const builtInLanguages = [
    'de',
    'en',
    'fr',
    'ko',
    'pt-PT',
    'pt-BR',
    'ru',
    'tr-TR',
    'zh-CN',
];
export const builtInLanguageOptions = builtInLanguages.map((languageTag) => ({
    value: languageTag,
    title: languages[languageTag],
}));
export const builtInLanguageTagGuard = z.enum(builtInLanguages);
const resource = {
    de,
    en,
    fr,
    ko,
    'pt-PT': ptPT,
    'pt-BR': ptBR,
    ru,
    'tr-TR': trTR,
    'zh-CN': zhCN,
};
export const getDefaultLanguageTag = (language) => builtInLanguageTagGuard.or(fallback('en')).parse(language);
export const isBuiltInLanguageTag = (language) => builtInLanguageTagGuard.safeParse(language).success;
export default resource;
