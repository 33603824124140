const translation = {
    input: {
        username: 'Username',
        password: 'Password',
        email: 'Email',
        phone_number: 'Phone number',
        confirm_password: 'Confirm password',
        search_region_code: 'Search region code',
    },
    secondary: {
        social_bind_with: 'Already had an account? Sign in to link {{methods, list(type: disjunction;)}} with your social identity.',
    },
    action: {
        sign_in: 'Sign in',
        continue: 'Continue',
        create_account: 'Create account',
        create_account_without_linking: 'Create account without linking',
        create: 'Create',
        enter_passcode: 'Enter verification code',
        confirm: 'Confirm',
        cancel: 'Cancel',
        save_password: 'Save password',
        bind: 'Link with {{address}}',
        bind_and_continue: 'Link and continue',
        back: 'Go back',
        nav_back: 'Back',
        agree: 'Agree',
        got_it: 'Got it',
        sign_in_with: 'Continue with {{name}}',
        forgot_password: 'Forgot your password?',
        switch_to: 'Switch to {{method}}',
        sign_in_via_passcode: 'Sign in with verification code',
        sign_in_via_password: 'Sign in with password',
        change: 'Change {{method}}',
        link_another_email: 'Link another email',
        link_another_phone: 'Link another phone',
        link_another_email_or_phone: 'Link another email or phone',
        show_password: 'Show password',
    },
    description: {
        email: 'email',
        phone_number: 'phone number',
        username: 'username',
        reminder: 'Reminder',
        not_found: '404 Not Found',
        agree_with_terms: 'I have read and agree to the ',
        agree_with_terms_modal: 'To proceed, please agree to the <link></link>.',
        terms_of_use: 'Terms of Use',
        sign_in: 'Sign in',
        privacy_policy: 'Privacy Policy',
        create_account: 'Create account',
        or: 'or',
        and: 'and',
        enter_passcode: 'The verification code has been sent to your {{address}} {{target}}',
        passcode_sent: 'The verification code has been resent',
        resend_after_seconds: 'Resend after <span>{{seconds}}</span> seconds',
        resend_passcode: 'Resend verification code',
        create_account_id_exists: 'The account with {{type}} {{value}} already exists, would you like to sign in?',
        link_account_id_exists: 'The account with {{type}} {{value}} already exists. Would you like to link?',
        sign_in_id_does_not_exist: 'The account with {{type}} {{value}} does not exist, would you like to create a new account?',
        sign_in_id_does_not_exist_alert: 'The account with {{type}} {{value}} does not exist.',
        create_account_id_exists_alert: 'The account with {{type}} {{value}} is linked to another account. Please try another {{type}}.',
        social_identity_exist: 'The {{type}} {{value}} is linked to another account. Please try another {{type}}.',
        bind_account_title: 'Link or create account',
        social_create_account: 'You can create a new account.',
        social_link_email: 'You can link another email',
        social_link_phone: 'You can link another phone',
        social_link_email_or_phone: 'You can link another email or phone',
        social_bind_with_existing: 'We find a related account, you can link it directly.',
        reset_password: 'Reset password',
        reset_password_description: 'Enter the {{types, list(type: disjunction;)}} associated with your account, and we’ll send you the verification code to reset your password.',
        new_password: 'New password',
        set_password: 'Set password',
        password_changed: 'Password changed',
        no_account: 'No account yet? ',
        have_account: 'Already had an account?',
        enter_password: 'Enter password',
        enter_password_for: 'Sign in with the password to {{method}} {{value}}',
        enter_username: 'Set username',
        enter_username_description: 'Username is an alternative for sign-in. Username should contain only letters, numbers, and underscores.',
        link_email: 'Link email',
        link_phone: 'Link phone',
        link_email_or_phone: 'Link email or phone',
        link_email_description: 'For added security, please link your email with the account.',
        link_phone_description: 'For added security, please link your phone with the account.',
        link_email_or_phone_description: 'For added security, please link your email or phone with the account.',
        continue_with_more_information: 'For added security, please complete below account details.',
        create_your_account: 'Create your account',
        sign_in_to_your_account: 'Sign in to your account',
        no_region_code_found: 'No region code found',
    },
    error: {
        general_required: `{{types, list(type: disjunction;)}} is required`,
        general_invalid: `The {{types, list(type: disjunction;)}} is invalid`,
        username_required: 'Username is required',
        password_required: 'Password is required',
        username_exists: 'Username already exists',
        username_should_not_start_with_number: 'Username should not start with a number',
        username_invalid_charset: 'Username should only contain letters, numbers, or underscores.',
        invalid_email: 'The email is invalid',
        invalid_phone: 'The phone number is invalid',
        password_min_length: 'Password requires a minimum of {{min}} characters',
        invalid_password: 'Password requires a minimum of {{min}} characters and contains a mix of letters, numbers, and symbols.',
        passwords_do_not_match: 'Your passwords don’t match. Please try again.',
        invalid_passcode: 'The verification code is invalid',
        invalid_connector_auth: 'The authorization is invalid',
        invalid_connector_request: 'The connector data is invalid',
        unknown: 'Unknown error. Please try again later.',
        invalid_session: 'Session not found. Please go back and sign in again.',
        timeout: 'Request timeout. Please try again later.',
    },
    demo_app: {
        notification: 'Tip: Create an account first to test the sign-in experience.',
    },
};
const en = Object.freeze({
    translation,
});
export default en;
