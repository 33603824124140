// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["Native"] = "Native";
    ApplicationType["SPA"] = "SPA";
    ApplicationType["Traditional"] = "Traditional";
    ApplicationType["MachineToMachine"] = "MachineToMachine";
})(ApplicationType || (ApplicationType = {}));
export var SignInMode;
(function (SignInMode) {
    SignInMode["SignIn"] = "SignIn";
    SignInMode["Register"] = "Register";
    SignInMode["SignInAndRegister"] = "SignInAndRegister";
})(SignInMode || (SignInMode = {}));
export var UsersPasswordEncryptionMethod;
(function (UsersPasswordEncryptionMethod) {
    UsersPasswordEncryptionMethod["Argon2i"] = "Argon2i";
})(UsersPasswordEncryptionMethod || (UsersPasswordEncryptionMethod = {}));
