// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { oidcModelInstancePayloadGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    modelName: z.string().max(64),
    id: z.string().max(128),
    payload: oidcModelInstancePayloadGuard,
    expiresAt: z.number(),
    consumedAt: z.number().nullable().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    modelName: z.string().max(64),
    id: z.string().max(128),
    payload: oidcModelInstancePayloadGuard,
    expiresAt: z.number(),
    consumedAt: z.number().nullable(),
});
export const OidcModelInstances = Object.freeze({
    table: 'oidc_model_instances',
    tableSingular: 'oidc_model_instance',
    fields: {
        tenantId: 'tenant_id',
        modelName: 'model_name',
        id: 'id',
        payload: 'payload',
        expiresAt: 'expires_at',
        consumedAt: 'consumed_at',
    },
    fieldKeys: [
        'tenantId',
        'modelName',
        'id',
        'payload',
        'expiresAt',
        'consumedAt',
    ],
    createGuard,
    guard,
});
