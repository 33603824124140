// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { arbitraryObjectGuard, configurableConnectorMetadataGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().max(128),
    syncProfile: z.boolean().optional(),
    connectorId: z.string().max(128),
    config: arbitraryObjectGuard.optional(),
    metadata: configurableConnectorMetadataGuard.optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(128),
    syncProfile: z.boolean(),
    connectorId: z.string().max(128),
    config: arbitraryObjectGuard,
    metadata: configurableConnectorMetadataGuard,
    createdAt: z.number(),
});
export const Connectors = Object.freeze({
    table: 'connectors',
    tableSingular: 'connector',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        syncProfile: 'sync_profile',
        connectorId: 'connector_id',
        config: 'config',
        metadata: 'metadata',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'syncProfile',
        'connectorId',
        'config',
        'metadata',
        'createdAt',
    ],
    createGuard,
    guard,
});
